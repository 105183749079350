import moment from 'moment';

export function getFirstThursdayOfNextMonth(): moment.Moment {
  const now = moment();
  const firstThursdayOfNextMonth = moment()
    .add(1, 'months')
    .startOf('month')
    .day(4);
  if (firstThursdayOfNextMonth.month() === now.month()) {
    firstThursdayOfNextMonth.add(1, 'week');
  }
  return firstThursdayOfNextMonth;
}
