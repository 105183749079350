import { FC, HTMLAttributes } from 'react';

import cx from 'classnames';

import Circle from 'components/Circle/Circle';
import { IChampionClubBenefit } from 'models/IChampionClubBenefit';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  championClubBenefit: IChampionClubBenefit;
}

const ChampionClubBenefitDetail: FC<IProps> = ({
  championClubBenefit,
  className,
  ...props
}) => {
  return (
    <div
      {...props}
      className={cx('d-flex flex-column align-items-center py-4', className)}
    >
      {championClubBenefit.lottery_asset?.media_file.url && (
        <Circle
          className="bg-white mb-4 mr-3 mr-md-0 flex-grow-0 flex-shrink-0 position-relative"
          uniqueSize={64}
        >
          <img
            loading="lazy"
            className="w-100 h-100 position-absolute top-0 left-0 object-fit-cover d-block"
            src={championClubBenefit.lottery_asset.media_file.url}
            alt={
              championClubBenefit.lottery_asset?.media_alt ||
              `${championClubBenefit.heading} image`
            }
          />
        </Circle>
      )}
      <div className="w-100">
        <h4 className="text-center">{championClubBenefit.heading}</h4>
        <p className="text-center">{championClubBenefit.expert}</p>
      </div>
    </div>
  );
};

export default ChampionClubBenefitDetail;
