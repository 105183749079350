import { FC, HTMLAttributes } from 'react';

import cx from 'classnames';

import s from './Circle.module.scss';

interface IProps extends HTMLAttributes<HTMLElement> {
  className?: string;
  size?: string;
  uniqueSize?: number;
}

const Circle: FC<IProps> = ({
  size,
  uniqueSize,
  children,
  className,
  ...props
}) => {
  return (
    <div
      {...(uniqueSize
        ? { style: { width: `${uniqueSize}px`, height: `${uniqueSize}px` } }
        : {})}
      className={cx(
        s.circle,
        s[`circle-${size}`],
        'overflow-hidden',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
};

export default Circle;
