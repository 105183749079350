import { FC, useState } from 'react';
import { Accordion } from 'react-bootstrap';

import cx from 'classnames';

import { CardProps } from 'components/Card/Card';
import ChampionClubPlanAccordionItem from 'components/ChampionClubPlanAccordionItem/ChampionClubPlanAccordionItem';
import { IChampionClubPlan } from 'models/IChampionClubPlan';

interface IProps extends CardProps {
  championClubPlanSelectedID?: number;
  championClubPlans: IChampionClubPlan[];
  onAddCart?: (
    championClubPlan: IChampionClubPlan,
    commenceNowInd: number,
  ) => void;
}

const ChampionClubPlansAccordion: FC<IProps> = ({
  championClubPlans,
  championClubPlanSelectedID,
  onAddCart,
  className,
  ...props
}) => {
  const [championClubPlanOpenedID, setChampionClubPlanOpenedID] = useState<
    number | undefined
  >(
    parseInt(
      (
        championClubPlans.find(
          championClubPlan =>
            championClubPlan.ticket_book_tag?.label === 'Popular',
        )?.id || ''
      ).toString(),
      10,
    ),
  );
  return (
    <Accordion
      {...props}
      className={cx(className, 'accordion-with-border-radius')}
      onSelect={eventKey => {
        setChampionClubPlanOpenedID(
          eventKey ? parseInt(eventKey, 10) : undefined,
        );
      }}
      activeKey={(championClubPlanOpenedID || '').toString()}
    >
      {championClubPlans.map(championClubPlan => (
        <ChampionClubPlanAccordionItem
          opened={championClubPlanOpenedID === championClubPlan.id}
          selected={championClubPlanSelectedID === championClubPlan.id}
          key={championClubPlan.id}
          className="mb-3 no-shadow"
          championClubPlan={championClubPlan}
          onAddCart={onAddCart}
        />
      ))}
    </Accordion>
  );
};

export default ChampionClubPlansAccordion;
