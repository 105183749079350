import Link from 'next/link';
import { FC, useMemo } from 'react';
import { Button, ButtonProps, Col, Row } from 'react-bootstrap';

import cx from 'classnames';
import moment from 'moment';

import {
  ChampionClubDrawSteps,
  IChampionClubDraw,
} from 'models/IChampionClubDraw';

import Card, { CardProps } from '../Card/Card';

interface IProps extends CardProps {
  championClubDraw: IChampionClubDraw;
  btnProps?: ButtonProps;
}

const ChampionClubDrawCard: FC<IProps> = ({
  championClubDraw,
  btnProps = {},
  className,
}) => {
  const isOpen = useMemo(
    () =>
      championClubDraw._workflow_current_step === ChampionClubDrawSteps.open,
    [championClubDraw],
  );

  return (
    <Card className={cx('overflow-hidden', { 'bg-gold': isOpen }, className)}>
      <Row noGutters>
        <Col md="12">
          <Card.Body
            className={cx('p-4 position-relative', {
              'pr-4 pr-lg-0': !isOpen,
            })}
          >
            {isOpen ? (
              <>
                <Row className="mb-3 p-2 bg-primary mt-n4 ml-n5 d-flex align-items-center justify-content-center justify-content-lg-between">
                  <Col className="col-12 col-lg-8 text-center text-lg-left ">
                    <h4 className="font-weight-semi-bold my-2 my-lg-3 text-white">
                      {championClubDraw.heading}
                    </h4>
                  </Col>
                  <p className="text-secondary mb-0">
                    {championClubDraw.description}
                  </p>
                </Row>
                {championClubDraw._workflow_current_step ===
                  ChampionClubDrawSteps.open && (
                  <Link href="/subscribe" prefetch={false}>
                    <Button
                      variant="dark-primary"
                      as="a"
                      className="mt-2"
                      {...btnProps}
                    >
                      Become a Champion
                    </Button>
                  </Link>
                )}
              </>
            ) : (
              <>
                <Row className="mb-3 p-4 bg-primary mt-n4 ml-n5 d-flex align-items-center ">
                  <Col className="col-12 col-lg-8 text-center text-lg-left ">
                    <h4 className="text-white">
                      Draw {championClubDraw.draw_no}
                    </h4>
                  </Col>
                  <Col className="d-flex col-12 justify-content-center text-lg-left col-lg-4">
                    <p className="text-white  mb-0">Drawn on &nbsp;</p>
                    <p className="text-secondary mb-0">
                      {moment(championClubDraw.draw_datetime).format(
                        'DD/MM/YYYY',
                      )}
                    </p>
                    <p className="text-white mb-0">&nbsp; at &nbsp;</p>
                    <p className="text-secondary mb-0">
                      {moment(championClubDraw.draw_datetime).format('hh:mm a')}
                    </p>
                  </Col>
                </Row>
                {championClubDraw.winner_card_detail && (
                  <div
                    className="pr-lg-4 overflow-auto overflow-lg-hidden"
                    style={{ width: '100%' }}
                    dangerouslySetInnerHTML={{
                      __html: championClubDraw.winner_card_detail,
                    }}
                  />
                )}
              </>
            )}
          </Card.Body>
        </Col>
      </Row>
    </Card>
  );
};

export default ChampionClubDrawCard;
