import { FC, useState } from 'react';
import { Accordion, Badge, Button, Col, Form, Row } from 'react-bootstrap';
import { FaCheck, FaChevronDown } from 'react-icons/fa';

import cx from 'classnames';
import moment from 'moment';
import { getFirstThursdayOfNextMonth } from 'utils/getFirstThursdayOfNextMonth';

import Card, { CardProps } from 'components/Card/Card';
import { IChampionClubPlan } from 'models/IChampionClubPlan';

interface IProps extends CardProps {
  selected?: boolean;
  opened?: boolean;
  championClubPlan: IChampionClubPlan;
  onAddCart?: (
    championClubPlan: IChampionClubPlan,
    commenceNowInd: number,
  ) => void;
}

const ChampionClubPlanAccordionItem: FC<IProps> = ({
  selected = false,
  opened,
  championClubPlan,
  onAddCart = () => {},
  className,
  ...props
}) => {
  const isCommenceNowSelectable = getFirstThursdayOfNextMonth().isSameOrAfter(
    moment().add(5, 'days'),
  );
  const [commenceNowInd, setCommenceNowInd] = useState(
    isCommenceNowSelectable ? 1 : 0,
  );
  return (
    <Card
      {...props}
      key={championClubPlan.id}
      className={cx(className, 'transition', {
        'bg-white border-primary border border-width-sm': opened,
        'bg-gold': !opened,
      })}
    >
      <Card.Header>
        <Row className="justify-content-between pb-3">
          <Col>
            <Accordion.Toggle
              as={Button}
              variant="link"
              eventKey={championClubPlan.id.toString()}
              className="px-0 btn-block"
            >
              <Row className="align-items-center">
                {championClubPlan.ticket_book_tag?.label && (
                  <Col lg="auto" className="d-flex justify-content-start mb-2">
                    <strong className="h3 mb-0 text-left mr-5 text-primary font-weight-bold">
                      {championClubPlan.ticket_book_tag?.label}
                    </strong>
                  </Col>
                )}
                <Col className="d-flex align-items-center">
                  <strong className="mr-2 text-left flex-grow-1">
                    {championClubPlan.plan_title}
                  </strong>
                  {selected && (
                    <Badge className="mx-2" variant="dark-primary">
                      Selected
                    </Badge>
                  )}
                  <FaChevronDown color="var(--primary)" />
                </Col>
              </Row>
            </Accordion.Toggle>
          </Col>
          <Col lg="auto">
            <Row className="justify-content-between">
              <Col xs="auto" className="d-flex align-items-center">
                <strong
                  className={cx(
                    { 'text-primary': opened },
                    'h1 m-0 font-weight-bold',
                  )}
                >
                  ${championClubPlan.dollar_value}
                </strong>
              </Col>
              <Col xs="auto">
                <Button
                  onClick={() => onAddCart(championClubPlan, commenceNowInd)}
                  variant="dark-primary"
                  className="white-space-nowrap btn-block"
                  data-cy="champion-club-plan-accordion-item-btn-add-to-cart"
                  disabled={selected}
                >
                  Join
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Header>
      <Accordion.Collapse eventKey={championClubPlan.id.toString()}>
        <Card.Body>
          <Row className="mb-n4">
            <Col className="mb-4 d-flex flex-column" lg={4}>
              <div className="border border-width-sm border-primary rounded-lg p-4 flex-grow-1">
                <h4 className="text-primary mb-4">
                  {championClubPlan.monthly_subscription_heading}
                </h4>
                <p>{championClubPlan.monthly_subscription_description}</p>
                <ul className="list-style-type-none p-0 m-0">
                  {(championClubPlan.champion_club_plan_items || [])
                    .filter(({ monthly_item_label }) => monthly_item_label)
                    .map(championClubPlanItem => (
                      <li className="mb-2" key={championClubPlanItem.id}>
                        <div className="d-flex align-items-center">
                          <FaCheck
                            color="var(--danger)"
                            size={15}
                            className="mr-2 flex-grow-0 flex-shrink-0"
                          />
                          <strong className="text-primary">
                            {championClubPlanItem.monthly_item_label}
                          </strong>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            </Col>
            <Col className="mb-4 d-flex" lg={4}>
              <div className="border border-width-sm border-medium-grey rounded-lg p-4 flex-grow-1">
                <h4 className="text-dark-grey mb-4">
                  {championClubPlan.single_subscription_heading}
                </h4>
                <p>{championClubPlan.single_subscription_description}</p>
                <ul className="list-style-type-none p-0 m-0">
                  {(championClubPlan.champion_club_plan_items || [])
                    .filter(({ single_item_label }) => single_item_label)
                    .map(championClubPlanItem => (
                      <li className="mb-2" key={championClubPlanItem.id}>
                        <div className="d-flex align-items-center">
                          <FaCheck
                            color="var(--dark-grey)"
                            size={15}
                            className="mr-2 flex-grow-0 flex-shrink-0"
                          />
                          <strong className="text-dark-grey">
                            {championClubPlanItem.single_item_label}
                          </strong>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            </Col>
            <Col className="mb-4 d-flex" lg={4}>
              <div className="border border-width-sm border-medium-grey rounded-lg p-4 flex-grow-1 w-100">
                <h4 className="mb-4 text-primary">
                  When would you like to start your subscription.
                </h4>
                <Form className="w-100">
                  {[
                    {
                      value: 1,
                      title: 'Commence Now',
                      disabled: !isCommenceNowSelectable,
                      description:
                        'Your first payment will be debited on commencement of your subscription. All subsequent payments will be debited on the first Thursday of every month.',
                    },
                    {
                      value: 0,
                      title: 'Next scheduled billing',
                      disabled: false,
                      description:
                        'All payments will be debited on the first Thursday of every month.',
                    },
                  ].map(({ value, title, description, disabled }) => (
                    <Form.Group key={value}>
                      <Form.Label className="d-block w-100">
                        <div className="d-flex align-items-start">
                          <div>
                            <h5 className={cx({ 'text-dark-grey': disabled })}>
                              {title}
                            </h5>
                            <p
                              className={cx('font-weight-normal text-primary', {
                                'text-primary': !disabled,
                                'text-dark-grey': disabled,
                              })}
                            >
                              {description}
                            </p>
                          </div>
                          <Form.Check
                            type="radio"
                            name="cc_commence_now_ind"
                            className="flex-grow-0 flex-shrink-0 ml-2"
                            checked={commenceNowInd === value}
                            disabled={disabled}
                            onChange={() => setCommenceNowInd(value)}
                            value={value}
                          />
                        </div>
                      </Form.Label>
                    </Form.Group>
                  ))}
                </Form>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default ChampionClubPlanAccordionItem;
