import { GetServerSideProps } from 'next';
import dynamic from 'next/dynamic';
import ErrorPage from 'next/error';
import Head from 'next/head';
import { useCallback, useRef } from 'react';
import { Button, Col, Row } from 'react-bootstrap';

import { captureException } from '@sentry/nextjs';
import { getAllowPreviewFromCookie } from 'utils/permissions';
import requestValuesForMainLayout from 'utils/requestValuesForMainLayout';
import { scrollToElement } from 'utils/scrollTo';

import Card from 'components/Card/Card';
import ChampionClubBenefitDetail from 'components/ChampionClubBenefitDetail/ChampionClubBenefitDetail';
import ChampionClubDrawCard from 'components/ChampionClubDrawCard/ChampionClubDrawCard';
import ChampionClubPlansAccordion from 'components/ChampionClubPlansAccordion/ChampionClubPlansAccordion';
import DynamicSlider from 'components/DynamicSlider/DynamicSlider';
import RowWithOffsetCol from 'components/RowWithOffsetCol/RowWithOffsetCol';
import SectionWithContainer from 'components/SectionWithContainer/SectionWithContainer';
import { useApp } from 'contexts/appContext';
import { useCart } from 'contexts/cartContext';
import { IChampionClubBenefit } from 'models/IChampionClubBenefit';
import {
  ChampionClubDrawSteps,
  IChampionClubDraw,
} from 'models/IChampionClubDraw';
import { IChampionClubPlan } from 'models/IChampionClubPlan';
import { ICommonMainLayoutPropsForPages } from 'models/ICommonMainLayoutPropsForPages';
import { ILotteryFAQ } from 'models/ILotteryFAQ';
import { Personas } from 'models/IPersona';
import { IPersonaSupportiveMessage } from 'models/IPersonaSupportiveMessage';
import { IShoppingCartItem } from 'models/IShoppingCartItem';
import { ChampionClubBenefitService } from 'services/ChampionClubBenefitService';
import { ChampionClubDrawService } from 'services/ChampionClubDrawService';
import { ChampionClubPlanService } from 'services/ChampionClubPlanService';
import { LotteryFAQService } from 'services/LotteryFAQService';
import { PersonaSupportiveMessageService } from 'services/PersonaSupportiveMessageService';

import MainLayout from '../layouts/MainLayout';
import renderCommonMetaTags from '../utils/renderCommonMetaTags';

const FAQAccordion = dynamic(
  () => import('components/FAQAccordion/FAQAccordion'),
);

const SupportiveMessageRow = dynamic(
  () => import('components/SupportiveMessageRow/SupportiveMessageRow'),
);

const SLIDER_SETTING = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerPadding: '55px',
  centerMode: true,
};

interface IProps {
  statusCode?: number;
  host: string;
  championClubDraw: IChampionClubDraw;
  championClubBenefits: IChampionClubBenefit[];
  championClubPlans: IChampionClubPlan[];
  faqs: ILotteryFAQ[];
  supportiveMessage?: IPersonaSupportiveMessage;
  commonPropsForMainLayout: ICommonMainLayoutPropsForPages;
}

export const getServerSideProps: GetServerSideProps = async ({ req }) => {
  try {
    const allowToPreview = await getAllowPreviewFromCookie(req.headers.cookie);
    const championClubDrawService = new ChampionClubDrawService();
    const championClubBenefitService = new ChampionClubBenefitService();
    const championClubPlanService = new ChampionClubPlanService();
    const lotteryFaqService = new LotteryFAQService();
    const personaSupportiveMessageService = new PersonaSupportiveMessageService();
    const [
      { data: championClubDraws },
      { data: championClubBenefits },
      { data: championClubPlans },
      { data: faqs },
      supportiveMessage,
      commonPropsForMainLayout,
    ] = await Promise.all([
      championClubDrawService.getDrawsByTypes([ChampionClubDrawSteps.open]),
      championClubBenefitService.getChampionClubBenefits(),
      championClubPlanService.getChampionClubPlans(),
      lotteryFaqService.getFAQsBySlug('subscribe'),
      personaSupportiveMessageService.getSupportiveMessageByPersonaAndPage(
        Personas.Unknown,
        'subscribe',
      ),
      requestValuesForMainLayout(allowToPreview),
    ]);

    return {
      props: {
        championClubDraw: championClubDraws?.[0] || null,
        championClubBenefits: championClubBenefits?.slice(0, 4),
        championClubPlans,
        faqs,
        supportiveMessage,
        commonPropsForMainLayout,
      },
    };
  } catch (error) {
    captureException(error);
    console.error('[ERROR]', error);
    return {
      props: {
        statusCode: error.status || null,
      },
    };
  }
};

const ChampionsClubSubscriptionPage = ({
  statusCode = 200,
  host = '',
  championClubDraw,
  championClubBenefits = [],
  championClubPlans = [],
  faqs = [],
  supportiveMessage,
  commonPropsForMainLayout,
}: IProps) => {
  const {
    state: { persona },
  } = useApp();
  const {
    actions: { addChampionClubPlanToCart },
    state: { userCart },
  } = useCart();
  const plansSectionRef = useRef<HTMLDivElement>(null);

  const overviewElement = useRef<HTMLDivElement>();
  const benefitsElement = useRef<HTMLDivElement>();
  const comparePlansElement = useRef<HTMLDivElement>();
  const faqsElement = useRef<HTMLDivElement>();

  const handleAddToCart = useCallback(
    (championClubPlan: IChampionClubPlan, commenceNowInd: number) => {
      addChampionClubPlanToCart(championClubPlan, !!commenceNowInd);
    },
    [addChampionClubPlanToCart],
  );

  const championClubPlanSelectedID = (userCart?.shopping_cart_items || []).find(
    (shoppingCartItem: IShoppingCartItem) =>
      shoppingCartItem.champion_club_plan_id,
  )?.champion_club_plan_id;

  if (statusCode !== 200) {
    return <ErrorPage statusCode={statusCode} />;
  }

  return (
    <>
      <Head>
        {renderCommonMetaTags(
          'Surf Life Saving Lottery - Champions Club Description',
          'Surf Life Saving Lottery - Champions Club Description',
          undefined,
          `${host}/champions-club-subscription`,
          undefined,
          'Surf Life Saving Lottery - Champions Club Description',
          `${host}/champions-club-subscription`,
        )}
      </Head>

      <MainLayout {...commonPropsForMainLayout}>
        <SectionWithContainer
          noPadding
          className="bg-dark-primary pt-8 pb-7 pb-md-11"
        >
          <RowWithOffsetCol>
            <div className="d-flex flex-column align-items-center">
              <img
                loading="lazy"
                src="/imgs/championsClubBadge-negative.svg"
                alt="Champions Club Badge"
                style={{ width: '240px' }}
                className="overflow-hidden no-shadow p-3"
              />

              <h1 className="heading heading-gold heading-center text-gold text-center">
                {persona?.cc_page_heading}
              </h1>
              <p className="text-center text-white mb-6">
                {persona?.cc_page_sub_heading}
              </p>
              <Button
                variant="gold"
                onClick={() => {
                  if (plansSectionRef.current) {
                    scrollToElement(plansSectionRef.current, 'top');
                  }
                }}
              >
                Subscribe today
              </Button>
            </div>
          </RowWithOffsetCol>
        </SectionWithContainer>

        {persona?.cc_page_sub_hero_image_asset?.media_file?.url && (
          <SectionWithContainer className="mt-n4 mt-sm-0 mt-md-n11">
            <Card className="overflow-hidden mx-xs-n-container rounded-xs-none position-relative vh-50 shadow-lg">
              <img
                loading="lazy"
                className="d-block position-absolute object-fit-cover h-100 w-100 "
                src={persona.cc_page_sub_hero_image_asset?.media_file?.url}
                alt={
                  persona.cc_page_sub_hero_image_asset?.media_alt ||
                  `${persona.cc_page_heading} image`
                }
              />
            </Card>
          </SectionWithContainer>
        )}

        <SectionWithContainer className="pt-0" ref={overviewElement}>
          <Row className="mb-n4">
            <Col className="mb-4" md={8}>
              <Row className="align-items-center pb-5 mb-n3 justify-content-center justify-content-sm-start">
                <Col md="auto" className="d-flex justify-content-center mb-3">
                  <strong>Jump to:</strong>
                </Col>
                {[
                  { ref: overviewElement, title: 'Overview' },
                  {
                    ref: benefitsElement,
                    title: 'Benefits',
                  },
                  {
                    ref: comparePlansElement,
                    title: 'Compare plans',
                  },
                  {
                    ref: faqsElement,
                    title: 'FAQs',
                  },
                  {
                    id: 'champions-draw-calendar',
                    title: 'Draw Calendar',
                  },
                ].map(button => (
                  <Col
                    key={button.title}
                    xs="auto"
                    className="mb-3 px-0 px-sm-3"
                  >
                    <Button
                      size="sm"
                      variant="link"
                      onClick={() => {
                        const targetEl = button.id
                          ? document.getElementById(button.id)
                          : button.ref?.current;
                        if (!targetEl) return;
                        scrollToElement(targetEl, 'top');
                      }}
                    >
                      {button.title}
                    </Button>
                  </Col>
                ))}
              </Row>

              <h2 className="heading heading-dark-primary">
                How the Champions Club works
              </h2>
              <div>
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: persona?.cc_page_overview || '',
                  }}
                />

                <div id="champions-draw-calendar" className="mt-4">
                  <div>CHAMPIONS CLUB DRAW CALENDAR</div>
                  <div>
                    Now you&apos;ll never miss a Champions Club draw! Download
                    the calendar to keep up to date with prize home lotteries
                    and your exclusive Champions Club draws.
                  </div>

                  <Button
                    href="/files/SLS10298-Champions-Club-A4-Calendar-WEB-OP.pdf"
                    target="_blank"
                    className="mt-3"
                    style={{ background: '#00003c', border: 'none' }}
                  >
                    Click here to download Calendar
                  </Button>
                </div>
              </div>
            </Col>

            <Col className="mb-4" md={4}>
              {championClubDraw && (
                <ChampionClubDrawCard
                  displayImage={false}
                  championClubDraw={championClubDraw}
                  btnProps={{ size: 'sm' }}
                />
              )}
            </Col>
          </Row>
        </SectionWithContainer>

        <SectionWithContainer className="bg-gold" ref={benefitsElement}>
          <Row className="d-none d-md-flex mb-n3 justify-content-center">
            {championClubBenefits.map(
              (championClubBenefit: IChampionClubBenefit) => (
                <Col key={championClubBenefit.id} md={3} className="mb-3">
                  <ChampionClubBenefitDetail
                    championClubBenefit={championClubBenefit}
                  />
                </Col>
              ),
            )}
          </Row>

          <DynamicSlider
            className="d-block d-md-none mx-xs-n-container"
            {...SLIDER_SETTING}
          >
            {championClubBenefits.map(
              (championClubBenefit: IChampionClubBenefit) => (
                <div key={championClubBenefit.id} className="px-3">
                  <ChampionClubBenefitDetail
                    championClubBenefit={championClubBenefit}
                  />
                </div>
              ),
            )}
          </DynamicSlider>
        </SectionWithContainer>

        {supportiveMessage && (
          <SectionWithContainer containerProps={{ fluid: 'lg' }}>
            <SupportiveMessageRow
              className="mx-md-n-container"
              isChampionClub
              supportiveMessage={supportiveMessage}
            />
          </SectionWithContainer>
        )}

        <SectionWithContainer
          className="bg-light-grey"
          ref={comparePlansElement}
        >
          <RowWithOffsetCol className="mb-7">
            <h2 className="heading heading-dark-primary" ref={plansSectionRef}>
              Choose your plan
            </h2>
          </RowWithOffsetCol>

          <ChampionClubPlansAccordion
            onAddCart={handleAddToCart}
            championClubPlanSelectedID={championClubPlanSelectedID}
            championClubPlans={championClubPlans}
          />

          <Button
            className="mt-3"
            as="a"
            variant="link"
            href="/terms-conditions?section=championsClub"
            target="_blank"
          >
            View the Champions Club terms and conditions
          </Button>
        </SectionWithContainer>

        {!!faqs.length && (
          <SectionWithContainer ref={faqsElement}>
            <RowWithOffsetCol>
              <h2 className="heading heading-danger">
                Frequently asked questions
              </h2>
              <FAQAccordion faqs={faqs} />
            </RowWithOffsetCol>
          </SectionWithContainer>
        )}
      </MainLayout>
    </>
  );
};

export default ChampionsClubSubscriptionPage;
