import { IChampionClubDrawType } from 'models/IChampionClubDrawType';

import { ILotteryAsset } from './ILotteryAsset';

export enum ChampionClubDrawOutcomes {
  Archive = 1,
  Rejected = 0,
}

export enum ChampionClubDrawSteps {
  setup = 1,
  pendingApproval = 2,
  upcoming = 3,
  open = 4,
  close = 5,
  results = 6,
}

export interface IChampionClubDraw {
  id: number;
  draw_no: number;
  draw_datetime: string;
  open_datetime: string;
  close_datetime: string;
  lottery_asset_id: number;
  lottery_asset?: ILotteryAsset;
  heading: string;
  description: string;
  winner_card_detail?: string;
  champion_club_draw_type_id: number;
  champion_club_draw_type?: IChampionClubDrawType;
  _workflow_outcome?: ChampionClubDrawOutcomes;
  _workflow_current_step: ChampionClubDrawSteps;
}
